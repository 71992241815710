/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { Link, Scroll } from "react-scroll";
import { useLocation } from "react-router-dom";
import Mobile_Banner from "../../Assets/banner_mobile_logo.webp";
import azadiLogo from "../../Assets/azadiLogo.webp";
import tablet_Banner from "../../Assets/banner_tablet_logo.webp";
import "./Navbar.css";

import { BiGlobeAlt } from "react-icons/bi";
import { GiTrophy } from "react-icons/gi";
import { GiProgression } from "react-icons/gi";
import { TbReportSearch } from "react-icons/tb";
import { IoIosPeople } from "react-icons/io";
import { IoIosContacts } from "react-icons/io";
import { BsBank2 } from "react-icons/bs";
import { GiReceiveMoney } from "react-icons/gi";
import { MdPhotoLibrary } from "react-icons/md";
import { SiGooglenews } from "react-icons/si";
import { IoNotificationsCircleSharp } from "react-icons/io5";
import { GoRepo } from "react-icons/go";
import { BsPhone } from "react-icons/bs";
import { FaCodeBranch } from "react-icons/fa";
import { VscFeedback } from "react-icons/vsc";
import { AiOutlineForm } from "react-icons/ai";
import { TbZoomMoney } from "react-icons/tb";
import { MdSupportAgent } from "react-icons/md";
import { BsInfoSquare } from "react-icons/bs";
import { BsQuestionOctagon } from "react-icons/bs";
import { TbMapSearch } from "react-icons/tb";
import { BsCashCoin } from "react-icons/bs";
import { GiCash } from "react-icons/gi";
import { BsFillCalculatorFill } from "react-icons/bs";
import { Accordion } from "../../Reuseable-Components/Accordian/Accordion";

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const [aboutUsCaret, setAboutUsCaret] = useState(false);
  const [productsCaret, setProductsCaret] = useState(false);
  const [insuranceCaret, setInsuranceCaret] = useState(false);
  const [utilityCaret, setUtilityCaret] = useState(false);
  const [importantLinksCaret, setImportantLinksCaret] = useState(false);
  const [mediaCaret, setMediaCaret] = useState(false);
  const [archivesCaret, setArchivesCaret] = useState(false);
  const [getInTouchCaret, setGetInTouchCaret] = useState(false);
  const [forumCaret, setForumCaret] = useState(false);
  const [calculatorCaret, setCalculatorCaret] = useState(false);

  const [showMenu, setShowMenu] = useState(false);

  const [activeAccordion, setActiveAccordion] = useState(null);

  const location = useLocation();

  const wrapperRef = useRef(null);

  const viewInsurance = (selected_insurance_id) => {
    localStorage.setItem("selectedId", selected_insurance_id || 2);
  };

  const closeMenu = () => {
    setAboutUsCaret(false);
    setProductsCaret(false);
    setInsuranceCaret(false);
    setUtilityCaret(false);
    setImportantLinksCaret(false);
    setMediaCaret(false);
    setArchivesCaret(false);
    setGetInTouchCaret(false);
    setForumCaret(false);
    setCalculatorCaret(false);
    setOpen(false);
  };

  const itemVariants = {
    closed: {
      opacity: 0,
    },
    open: { opacity: 1 },
  };

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: 1,
      },
    },
  };

  const onMenuClickHandler = () => {
    setShowMenu(!showMenu);
    const btn = document.getElementById("menu-btn");
    btn.classList.toggle(`open`);
  };

  const onNavClickHandler = () => {
    setShowMenu(false);
    const btn = document.getElementById("menu-btn");
    btn.classList.toggle(`open`);
  };

  const handleAccordionClick = (accordionId) => {
    if (activeAccordion === accordionId) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(accordionId);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const hanleSkipToMainContent = () => {
    window.scroll({
      top: 400,
      behavior: "smooth",
    });
  };

  const accordions = [
    {
      id: 1,
      title: "About Us ",
      content: (
        <>
          <div
            className="ml-2 text-white font-semibold"
            onClick={() => {
              setOpen(false);
              onNavClickHandler();
            }}
          >
            <NavLink to="about_us/history">
              <p className="flex gap-2 items-center pt-3 ">
                <BiGlobeAlt size={16} /> History
              </p>
            </NavLink>
            <NavLink to="/about_us/awards_and_recognition">
              <p className="flex gap-2 pt-2 items-center ">
                <GiTrophy size={16} /> Awards &amp; Recognition
              </p>
            </NavLink>
            <NavLink to="about_us/keymilestone">
              <p className="flex gap-2 pt-2 items-center ">
                <GiProgression size={16} /> Key Milestones
              </p>
            </NavLink>
            {/* <NavLink to="/about_us/annual_report">
              <p className="flex gap-2 pt-2 items-center ">
                <TbReportSearch size={16} /> Annual Report
              </p>
            </NavLink> */}
            <NavLink to="/about_us/membership">
              <p className="flex gap-2 pt-2 items-center ">
                <IoIosPeople size={16} /> Membership
              </p>
            </NavLink>
          </div>
        </>
      ),
    },
    {
      id: 2,
      title: "Products",
      content: (
        <>
          {" "}
          <>
            <div
              className="ml-2 font-semibold text-white "
              onClick={() => {
                setOpen(false);
                onNavClickHandler();
              }}
            >
              <NavLink to="/products/accounts">
                <p className=" flex gap-2 items-center pt-2">
                  <IoIosContacts size={16} /> Account
                </p>
              </NavLink>
              <NavLink to="/products/deposits">
                <p className="flex gap-2 items-center pt-2">
                  <BsBank2 size={16} /> Deposits
                </p>
              </NavLink>
              <NavLink to="/products/loans">
                <p className="flex gap-2 items-center pt-2">
                  <GiReceiveMoney size={16} /> Loan
                </p>
              </NavLink>
            </div>
          </>
        </>
      ),
    },
    {
      id: 3,
      title: "Insurance",
      content: (
        <>
          <div className="ml-2 text-white font-semibold">
            <NavLink
              to="https://www.iffcotokio.co.in/portal/corporate/zahp0puktsphyqtyvokpit8fx6h2xqlt"
              target={"_blank"}
            >
              <p className="flex gap-2 items-center pt-3 ">
                Buy Instant Insurance
              </p>
            </NavLink>
            <NavLink
              to="/insurance/pradhan_mantri_yojana"
              onClick={() => viewInsurance(1)}
            >
              <p className="flex gap-2 item-center pt-3">
                Pradhan Mantri Suraksh Bima Yojana (PMSBY)
              </p>
            </NavLink>
            <NavLink
              to="/insurance/pradhan_mantri_yojana"
              onClick={() => viewInsurance(2)}
            >
              <p className=" flex gap-2 items-center pt-2">
                Pradhan Mantri Jeevan Jyoti Bima Yojana (PMJJBY)
              </p>
            </NavLink>
          </div>
        </>
      ),
    },
    {
      id: 4,
      title: "Utility Bill Payments",
      content: (
        <>
          {" "}
          <div
            className="flex items-start rounded-lg hover:bg-gray-50"
            onClick={() => {
              setOpen(false);
              onNavClickHandler();
            }}
          >
            <div className="ml-4 text-white font-semibold">
              <a href="https://sikkimpower.co.in/Qbilpay.php" target={"_blank"}>
                <p className="flex gap-2 items-center pt-2">
                  Electricity Bill Payments (Other Zones)
                </p>
              </a>
              <a
                href="http://power.sikkim.gov.in/electricity-bill-payment/quick-pay-online/"
                target={"_blank"}
              >
                <p className="flex gap-2 items-center pt-2 ">
                  Electricity Bill Payments (Gangtok Zone)
                </p>
              </a>
            </div>
          </div>
        </>
      ),
    },
    {
      id: 5,
      title: "Important Links",
      content: (
        <>
          <div
            className=""
            onClick={() => {
              setOpen(false);
              onNavClickHandler();
            }}
          >
            <div className="ml-2 text-white font-semibold">
              <a href="https://rbi.org.in/" target={"_blank"}>
                <p className="flex gap-2 items-center pt-3  ">
                  Reserve Bank of India
                </p>
              </a>
              <a href="https://www.nabard.org/#firstPage" target={"_blank"}>
                <p className="flex gap-2 items-center pt-3 ">NABARD</p>
              </a>
              <a href="http://nafscob.org/" target={"_blank"}>
                <p className=" flex gap-2 items-center pt-3">NAFSCOB</p>
              </a>
              <a
                href="https://files.siscobank.com/RBI_guidelines.pdf"
                target={"_blank"}
              >
                <p className=" flex gap-2 items-center pt-3">RBI Guidelines</p>
              </a>
              <a
                href="https://files.siscobank.com/SOVAAndroidTrojan.pdf"
                target={"_blank"}
              >
                <p className=" flex gap-2 items-center pt-3">SOVA Trojan</p>
              </a>
            </div>
          </div>
        </>
      ),
    },
    {
      id: 6,
      title: "Media",
      content: (
        <>
          <div
            className="flex items-start rounded-lg "
            onClick={() => {
              setOpen(false);
              onNavClickHandler();
            }}
          >
            <div className="ml-2 font-semibold text-white">
              <NavLink to="/media/photo_gallery">
                <p className=" flex items-center gap-2 pt-3 ">
                  <MdPhotoLibrary size={16} /> Photo Gallery
                </p>
              </NavLink>
              <NavLink to="/media/news_and_events">
                <p className="flex gap-2 items-center pt-2">
                  <SiGooglenews size={16} />
                  News and Events
                </p>
              </NavLink>
              <NavLink to="/media/circulars_and_notifications">
                <p className="flex gap-2 items-center pt-2">
                  <IoNotificationsCircleSharp size={16} />
                  Circulars and Notification
                </p>
              </NavLink>
            </div>
          </div>
        </>
      ),
    },
    {
      id: 7,
      title: "Archives",
      content: (
        <>
          <div
            className="flex items-start rounded-lg"
            onClick={() => {
              setOpen(false);
              onNavClickHandler();
            }}
          >
            <div className="ml-2 font-semibold text-white">
              <NavLink to="/archives/annual_reports">
                <p className="flex gap-2 items-center pt-3 ">
                  <GoRepo size={16} /> Annual Reports
                </p>
              </NavLink>
              <NavLink to="/archives/circulars_and_notifications">
                <p className="flex gap-2 items-center pt-2 ">
                  <IoNotificationsCircleSharp size={16} />
                  Circulars and Notification
                </p>
              </NavLink>
              <NavLink to="/archives/news_and_events">
                <p className="flex gap-2 items-center pt-2 ">
                  <SiGooglenews size={16} />
                  News and Events
                </p>
              </NavLink>
            </div>
          </div>
        </>
      ),
    },
    {
      id: 8,
      title: "Get In Touch",
      content: (
        <>
          <div
            className="flex items-start rounded-lg "
            onClick={() => {
              setOpen(false);
              onNavClickHandler();
            }}
          >
            <div className="ml-2 font-semibold text-white">
              <NavLink to="/get_in_touch/contact_us">
                <p className="flex items-center gap-2 pt-3 ">
                  <BsPhone size={16} /> Contact Us
                </p>
              </NavLink>
              <NavLink to="/get_in_touch/find_atms_or_branches">
                <p className="flex items-center gap-2 pt-3">
                  <FaCodeBranch size={16} /> Find ATMs /Branches
                </p>
              </NavLink>
              <NavLink to="/get_in_touch/feedback_or_grievance_redressal">
                <p className="flex gap-2 items-center pt-3">
                  <VscFeedback size={16} /> Feedback / Grievance Redressal
                </p>
              </NavLink>
              <NavLink to="/get_in_touch/request_for_service">
                <p className="flex gap-2 items-center pt-3">
                  <AiOutlineForm size={16} /> Request for Service
                </p>
              </NavLink>
              <NavLink to="/get_in_touch/unclaimed_deposit">
                <p className="flex gap-2 items-center pt-3">
                  <TbZoomMoney size={20} /> Unclaimed Deposit
                </p>
              </NavLink>
              <NavLink to="/get_in_touch/ombudsman">
                <p className="flex gap-2 items-center pt-3">
                  <MdSupportAgent size={24} /> Ombudsman
                </p>
              </NavLink>
            </div>
          </div>
        </>
      ),
    },
    {
      id: 9,
      title: "Forum",
      content: (
        <>
          <div
            className="flex items-start rounded-lg "
            onClick={() => {
              setOpen(false);
              onNavClickHandler();
            }}
          >
            <div className="ml-4 text-white font-semibold">
              <NavLink to="/forum/rti">
                <p className="flex gap-2 items-center pt-3 ">
                  <BsInfoSquare size={16} /> RTIs
                </p>
              </NavLink>
              <NavLink to="/forum/faq">
                <p className="flex gap-2 items-center pt-3">
                  <BsQuestionOctagon size={16} /> FAQ's
                </p>
              </NavLink>
              <NavLink to="/forum/vigilance_cell">
                <p className="flex gap-2 items-center pt-3">
                  <TbMapSearch size={16} /> Vigilance Cell
                </p>
              </NavLink>
            </div>
          </div>
        </>
      ),
    },
    {
      id: 10,
      title: "Calculator",
      content: (
        <>
          <div
            className="flex items-start rounded-lg "
            onClick={() => {
              setOpen(false);
              onNavClickHandler();
            }}
          >
            <div className="ml-4 text-white font-semibold">
              <NavLink to="/calculator/emi_calculator">
                <p className="flex gap-2 items-center pt-3 ">
                  <BsCashCoin size={16} /> EMI Calculator
                </p>
              </NavLink>
              <NavLink to="/calculator/fd_calculator">
                <p className="flex gap-2 items-center pt-3">
                  <GiCash size={16} /> FD Calculator
                </p>
              </NavLink>
              <NavLink to="/calculator/rd_calculator">
                <p className="flex gap-2 items-center pt-3">
                  <BsFillCalculatorFill size={16} /> RD Calculator
                </p>
              </NavLink>
            </div>
          </div>
        </>
      ),
    },

    // Add more accordion items as needed
  ];

  return (
    <div ref={wrapperRef} className=" font-sans   sticky-top ">
      {/* ----------------------------------------------------MOBILE NAVBAR START-------------------------------------------------  */}
      <section id="nav-bar" className="sticky-top  ">
        <div className="lg:hidden z-30 mb-3  ">
          <span className="sr-only">Open menu</span>

          <button
            id="menu-btn"
            className="cursor-pointer  absolute top-4 left-3   md:top-4 md:left-10 "
            onClick={onMenuClickHandler}
          >
            <span className="hamtop"></span>
            <span className="hammiddle"></span>
            <span className="hambottom"></span>
          </button>
        </div>

        <AnimatePresence>
          {showMenu && (
            <motion.div
              className="bg-[#0E381D] shadow-xl absolute z-20 "
              initial={{ width: 0, height: "auto" }}
              animate={{
                width: "100%",
                height: "180vh",
                transition: { duration: 0.5 },
              }}
              exit={{
                width: 0,
                height: "screen",
                transition: { delay: 0.9, duration: 0.5 },
              }}
            >
              <motion.div
                className="grid grid-cols-1 text-sm"
                // className="flex flex-col p-4 gap-4"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                <NavLink to="/">
                  <motion.p
                    variants={itemVariants}
                    className="font-semibold text-white pt-5 pl-5"
                    onClick={() => {
                      setOpen(false);
                      onNavClickHandler();
                    }}
                  >
                    Home
                  </motion.p>
                </NavLink>

                <NavLink to="/careers">
                  <motion.p
                    variants={itemVariants}
                    className="font-semibold text-white pt-3 pl-5"
                    onClick={() => {
                      setOpen(false);
                      onNavClickHandler();
                    }}
                  >
                    {/* <span class="relative flex h-3 w-3">
                      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-700 opacity-75 left-14  top-3"></span>
                      <span class="relative inline-flex rounded-full h-3 w-3 bg-red-700 left-14 top-3"></span>
                    </span> */}
                    Careers
                    <div
                      class="relative -top-2 spinner-grow inline-block w-2 h-2 bg-current rounded-full opacity-0 bg-orange-600"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </motion.p>
                </NavLink>

                <NavLink to="/financial_inclusion">
                  <motion.p
                    variants={itemVariants}
                    className="font-semibold text-white pt-3 pl-5 "
                    onClick={() => {
                      setOpen(false);
                      onNavClickHandler();
                    }}
                  >
                    Financial Inclusion
                  </motion.p>
                </NavLink>

                <NavLink to="/check_rates">
                  <motion.p
                    variants={itemVariants}
                    className="font-semibold text-white pt-3 pl-5"
                    onClick={() => {
                      setOpen(false);
                      onNavClickHandler();
                    }}
                  >
                    Check Rates
                  </motion.p>
                </NavLink>

                <NavLink to="/blogs">
                  <motion.p
                    variants={itemVariants}
                    className="font-semibold text-white pt-3 pl-5"
                    onClick={() => {
                      setOpen(false);
                      onNavClickHandler();
                    }}
                  >
                    Blogs
                    {/* <div
                      class="relative -top-2 spinner-grow inline-block w-2 h-2 bg-current rounded-full opacity-0 bg-orange-600"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div> */}
                  </motion.p>
                </NavLink>

                {/* new accordion */}
                <div variants={itemVariants}>
                  {accordions.map((accordion) => (
                    <Accordion
                      key={accordion.id}
                      title={accordion.title}
                      content={accordion.content}
                      isOpen={accordion.id === activeAccordion}
                      onAccordionClick={() =>
                        handleAccordionClick(accordion.id)
                      }
                    />
                  ))}
                </div>

                <a href="https://app.siscobank.com/" target="_blank">
                  <div className="flex justify-center items-center mt-3">
                    <span className="inline-block px-6 py-2  text-[13px]  uppercase font-semibold bg-white bg-opacity-90 text-green-900  rounded-sm">
                      Login
                    </span>
                  </div>
                </a>
                {/* new accordion */}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* -------------------------------------MOBILE NAVBAR END----------------------------------------------------------  */}

        {/* -------------------------------------DESKTOP NAVBAR START------------------------------------------------------- */}
        <>
          <div className="sticky-top bg-[#0E381D] z-20 flex justify-center">
            <nav className="hidden lg:flex lg:items-center py-2 text-xs 2xl:text-base  ">
              <NavLink to="/">
                <div
                  className="flex items-center justify-center text-white nav-link hover:text-green-100 border-r border-white border-opacity-30  px-2"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                  }}
                >
                  <p className="">Home</p>
                </div>
              </NavLink>
              <NavLink to="/careers">
                <div
                  className="flex items-center justify-center text-white nav-link hover:text-green-200 border-r border-white border-opacity-30 px-2"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                  }}
                >
                  <p className="">Careers</p>
                  {/* <span class="relative flex h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-700 opacity-75 left-1  -top-2"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-red-700 left-1 -top-2"></span>
                  </span> */}
                  <div
                    class="relative -top-2 spinner-grow inline-block w-2 h-2 bg-current rounded-full opacity-0 bg-red-900"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </NavLink>
              <NavLink to="/financial_inclusion">
                <div
                  className="flex items-center justify-center text-white nav-link hover:text-green-200 border-r border-white border-opacity-30  px-2"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                  }}
                >
                  <p className=" ">Financial Inclusion</p>
                </div>
              </NavLink>
              <NavLink to="/check_rates">
                <div
                  className="flex items-center justify-center text-white nav-link hover:text-green-200 border-r border-white border-opacity-30  px-2"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                  }}
                >
                  <p className=" ">Check Rates</p>
                </div>
              </NavLink>

              <NavLink to="/blogs">
                <div
                  className="flex items-center justify-center text-white nav-link hover:text-green-200 border-r border-white border-opacity-30  px-2"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                  }}
                >
                  <p className=" ">Blogs </p>
                  {/* <div
                    class="relative -top-2 spinner-grow inline-block w-2 h-2 bg-current rounded-full opacity-0 bg-orange-600"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div> */}
                </div>
              </NavLink>

              <div className="relative">
                <button
                  type="button"
                  className="
              group text-white  inline-flex items-center   focus:outline-none   "
                  onMouseEnter={() => {
                    setAboutUsCaret(true);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                    setCalculatorCaret(false);
                  }}
                >
                  <div className="nav-link cursor-pointer border-r border-white border-opacity-30  px-2 flex items-center">
                    <span
                      className={
                        aboutUsCaret ? "underline underline-offset-4 " : ""
                      }
                    >
                      About Us
                    </span>
                    <NavbarCaret state={aboutUsCaret} />
                  </div>
                </button>
                <div
                  className={
                    aboutUsCaret
                      ? " opacity-100 -translate-y-1 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2  w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : " hidden opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 "
                  }
                >
                  <div
                    className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden "
                    onMouseLeave={() => closeMenu()}
                  >
                    <div className="relative grid bg-green-50 p-4 lg:px-6 lg:py-2">
                      <div className=" font-semibold text-green-700   2xl:text-lg ">
                        <NavLink to="/about_us/history">
                          <p className="flex  gap-2  content-center items-center hover:text-green-900  ">
                            <BiGlobeAlt size={16} /> History
                          </p>
                        </NavLink>

                        <NavLink to="/about_us/awards_and_recognition">
                          <p className=" flex gap-2  pt-4  content-center items-center hover:text-green-900">
                            <GiTrophy size={16} /> Awards &amp; Recognition
                          </p>
                        </NavLink>
                        <NavLink to="/about_us/keymilestone">
                          <p className=" flex gap-2 pt-4 content-center items-center hover:text-green-900">
                            <GiProgression size={16} /> Key Milestones
                          </p>
                        </NavLink>
                        {/* <NavLink to="/about_us/annual_report">
                          <p className=" flex gap-2  pt-4 content-center items-center hover:text-green-900">
                            <TbReportSearch size={16} /> Annual Report
                          </p>
                        </NavLink> */}
                        <NavLink to="/about_us/membership">
                          <p className=" flex gap-2 pt-4 content-center items-center hover:text-green-900">
                            {" "}
                            <IoIosPeople size={16} /> Membership
                          </p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative">
                <button
                  type="button"
                  className="group text-white  inline-flex items-center  focus:outline-none "
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(true);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                    setCalculatorCaret(false);
                  }}
                >
                  <div className="nav-link border-r border-white border-opacity-30  px-2 flex items-center">
                    <span
                      className={
                        productsCaret ? " underline underline-offset-4 " : ""
                      }
                    >
                      Products
                    </span>
                    <NavbarCaret state={productsCaret} />
                  </div>
                </button>
                <div
                  className={
                    productsCaret
                      ? " opacity-100 -translate-y-1 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : " hidden opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  }
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-green-50 p-3 lg:px-6 lg:py-3">
                      <div
                        className="-m-3 p-3 flex items-start rounded-lg"
                        onClick={() => setMediaCaret(false)}
                      >
                        <div className=" font-semibold  text-green-700 2xl:text-lg ">
                          <NavLink to="/products/accounts">
                            <p className="flex gap-2 items-center hover:text-green-900 ">
                              <IoIosContacts size={16} /> Accounts
                            </p>
                          </NavLink>
                          <NavLink to="/products/deposits">
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900 ">
                              <BsBank2 size={16} /> Deposits
                            </p>
                          </NavLink>
                          <a href="/products/loans">
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900">
                              {" "}
                              <GiReceiveMoney size={16} /> Loan
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative">
                <button
                  type="button"
                  className="group text-white inline-flex items-center  focus:outline-none"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(true);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                    setCalculatorCaret(false);
                  }}
                >
                  <div className="nav-link border-r border-white border-opacity-30  px-2 flex items-center">
                    <span
                      className={
                        insuranceCaret ? " underline underline-offset-4" : ""
                      }
                    >
                      Insurance
                    </span>
                    <NavbarCaret state={insuranceCaret} />
                  </div>
                </button>
                <div
                  className={
                    insuranceCaret
                      ? " opacity-100 -translate-y-1 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : " hidden opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  }
                  onMouseLeave={() => closeMenu()}
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-green-50  p-4 lg:px-6 lg:py-3">
                      <div className="-m-3 p-3 flex items-start rounded-lg ">
                        <div className=" text-green-600 font-extrabold 2xl:text-lg">
                          <a
                            href="https://www.iffcotokio.co.in/portal/corporate/zahp0puktsphyqtyvokpit8fx6h2xqlt"
                            target={"_blank"}
                          >
                            <p className="flex gap-2 items-center   hover:text-green-900">
                              Buy Instant Insurance
                            </p>
                          </a>
                          <NavLink
                            to="/insurance/pradhan_mantri_yojana"
                            onClick={() => viewInsurance(1)}
                          >
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900">
                              Pradhan Mantri Suraksh Bima Yojana (PMSBY)
                            </p>
                          </NavLink>
                          <NavLink
                            to="/insurance/pradhan_mantri_yojana"
                            onClick={() => viewInsurance(2)}
                          >
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900">
                              Pradhan Mantri Jeevan Jyoti Bima Yojana (PMJJBY)
                            </p>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative">
                <button
                  type="button"
                  className="group text-white  inline-flex items-center  font-medium focus:outline-none"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(true);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                    setCalculatorCaret(false);
                  }}
                >
                  <div className="nav-link border-r border-white border-opacity-30  px-2 flex items-center">
                    <span
                      className={
                        utilityCaret ? " underline underline-offset-4" : ""
                      }
                    >
                      Utility Bill Payments
                    </span>
                    <NavbarCaret state={utilityCaret} />
                  </div>
                </button>
                <div
                  className={
                    utilityCaret
                      ? "opacity-100 -translate-y-1 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : "hidden opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  }
                  onMouseLeave={() => closeMenu()}
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-green-50 lg:px-6 lg:py-3 p-4">
                      <div
                        className="-m-3 p-3 flex items-start rounded-lg "
                        onClick={() => setUtilityCaret(false)}
                      >
                        <div className="font-extrabold text-green-600 2xl:text-lg">
                          <a
                            href="https://sikkimpower.co.in/Qbilpay.php"
                            target={"_blank"}
                          >
                            <p className="flex gap-2 items-center hover:text-green-900">
                              Electricity Bill Payment (Other Zones)
                            </p>
                          </a>
                          <a
                            href="http://power.sikkim.gov.in/electricity-bill-payment/quick-pay-online/"
                            target={"_blank"}
                          >
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900">
                              Electricity Bill Payment (Gangtok Zone)
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative">
                <button
                  type="button"
                  className="group text-white  inline-flex items-center  focus:outline-none"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(true);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                    setCalculatorCaret(false);
                  }}
                >
                  <div className="nav-link border-r border-white border-opacity-30  px-2 flex items-center">
                    <span
                      className={
                        importantLinksCaret
                          ? "underline underline-offset-4"
                          : ""
                      }
                    >
                      Important Links
                    </span>
                    <NavbarCaret state={importantLinksCaret} />
                  </div>
                </button>
                <div
                  className={
                    importantLinksCaret
                      ? "opacity-100 -translate-y-1 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : "hidden opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  }
                  onMouseLeave={() => closeMenu()}
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-green-50 p-4 lg:px-6 lg:py-2">
                      <div
                        className="-m-3 p-3 flex items-start rounded-lg "
                        onClick={() => setImportantLinksCaret(false)}
                      >
                        <div className="font-extrabold text-green-600 2xl:text-lg">
                          <a href="https://rbi.org.in/" target={"_blank"}>
                            <p className="flex gap-2 items-center hover:text-green-900 ">
                              Reserve Bank of India
                            </p>
                          </a>
                          <a
                            href="https://www.nabard.org/#firstPage"
                            target={"_blank"}
                          >
                            <p className=" flex gap-2 items-center pt-4 hover:text-green-900">
                              NABARD
                            </p>
                          </a>
                          <a href="http://nafscob.org/" target={"_blank"}>
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900">
                              NAFSCOB
                            </p>
                          </a>
                          <a
                            href="https://files.siscobank.com/RBI_guidelines.pdf"
                            target={"_blank"}
                          >
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900">
                              RBI Guidelines
                            </p>
                          </a>
                          <a
                            href="https://files.siscobank.com/SOVAAndroidTrojan.pdf"
                            target={"_blank"}
                          >
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900">
                              Sova Trojan
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative">
                <button
                  type="button"
                  className="group text-white inline-flex items-center   focus:outline-none"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(true);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                    setCalculatorCaret(false);
                  }}
                >
                  <div className="nav-link border-r border-white border-opacity-30  px-2 flex items-center">
                    <span
                      className={
                        mediaCaret ? " underline underline-offset-4" : " "
                      }
                    >
                      Media
                    </span>
                    <NavbarCaret state={mediaCaret} />
                  </div>
                </button>
                <div
                  className={
                    mediaCaret
                      ? "opacity-100 -translate-y-1 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : "hidden opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  }
                  onMouseLeave={() => closeMenu()}
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-green-50 p-4 lg:px-6 lg:py-3">
                      <div
                        className="-m-3 p-3 flex items-start rounded-lg"
                        onClick={() => setMediaCaret(false)}
                      >
                        <div className=" text-green-700 font-semibold 2xl:text-lg">
                          <NavLink to="/media/photo_gallery">
                            <p className=" flex gap-3 items-center hover:text-green-900 ">
                              <MdPhotoLibrary size={16} /> Photo Gallery
                            </p>
                          </NavLink>
                          <NavLink to="/media/news_and_events">
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900">
                              <SiGooglenews size={16} /> News &amp; Events
                            </p>
                          </NavLink>
                          <NavLink to="/media/circulars_and_notifications">
                            <p className="flex gap-2 items-center pt-4  hover:text-green-900 ">
                              <IoNotificationsCircleSharp size={16} /> Circulars
                              &amp; Notification
                            </p>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative">
                <button
                  type="button"
                  className="group text-white inline-flex items-center   focus:outline-none"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(true);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                    setCalculatorCaret(false);
                  }}
                >
                  <div className="nav-link border-r border-white border-opacity-30  px-2 flex items-center">
                    <span
                      className={
                        archivesCaret ? " underline underline-offset-4" : ""
                      }
                    >
                      Archives
                    </span>
                    <NavbarCaret state={archivesCaret} />
                  </div>
                </button>
                <div
                  className={
                    archivesCaret
                      ? "opacity-100 -translate-y-1 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : "hidden opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  }
                  onMouseLeave={() => closeMenu()}
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-green-50 p-4 lg:px-6 lg:py-3">
                      <div
                        className="-m-3 p-3 flex items-start rounded-lg "
                        onClick={() => setArchivesCaret(false)}
                      >
                        <div className="text-green-700 font-semibold 2xl:text-lg">
                          <NavLink to="/archives/annual_reports">
                            <p className="flex gap-3 items-center  hover:text-green-900">
                              <GoRepo size={16} />
                              Annual Reports
                            </p>
                          </NavLink>
                          <NavLink to="/archives/circulars_and_notifications">
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900 ">
                              <IoNotificationsCircleSharp size={16} /> Circulars
                              &amp; Notification
                            </p>
                          </NavLink>
                          <NavLink to="/archives/news_and_events">
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900 ">
                              <SiGooglenews size={16} /> News &amp; Events
                            </p>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative">
                <button
                  type="button"
                  className="group text-white inline-flex items-center focus:outline-none"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(true);
                    setForumCaret(false);
                    setCalculatorCaret(false);
                  }}
                >
                  <div className="nav-link border-r border-white border-opacity-30  px-2 flex items-center">
                    <span
                      className={
                        getInTouchCaret ? " underline underline-offset-4" : ""
                      }
                    >
                      Get In Touch
                    </span>
                    <NavbarCaret state={getInTouchCaret} />
                  </div>
                </button>
                <div
                  className={
                    getInTouchCaret
                      ? "opacity-100 -translate-y-1 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : "hidden opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  }
                  onMouseLeave={() => closeMenu()}
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-green-50 lg:px-6 lg:py-3 p-4 ">
                      <div
                        className="-m-3 p-3 flex items-start rounded-lg "
                        onClick={() => setArchivesCaret(false)}
                      >
                        <div className="text-green-700 font-semibold 2xl:text-lg">
                          <NavLink to="/get_in_touch/contact_us">
                            <p className="hover:text-green-900 flex gap-3 items-center ">
                              {" "}
                              <BsPhone size={16} /> Contact Us
                            </p>
                          </NavLink>
                          <NavLink to="/get_in_touch/find_atms_or_branches">
                            <p className="flex items-center gap-2 pt-4 hover:text-green-900 ">
                              <FaCodeBranch size={16} /> Find ATMs / Branches
                            </p>
                          </NavLink>
                          <NavLink to="/get_in_touch/feedback_or_grievance_redressal">
                            <p className="flex items-center gap-2 pt-4 hover:text-green-900 ">
                              <VscFeedback size={16} /> Feedback / Grievance
                              Redressal
                            </p>
                          </NavLink>
                          <NavLink to="/get_in_touch/request_for_service">
                            <p className="flex items-center gap-2 pt-4 hover:text-green-900">
                              <AiOutlineForm size={16} /> Request for Service
                            </p>
                          </NavLink>
                          <NavLink to="/get_in_touch/unclaimed_deposit">
                            <p className="flex items-center gap-2 pt-4 hover:text-green-900">
                              <TbZoomMoney size={16} /> Unclaimed Deposit
                            </p>
                          </NavLink>
                          <NavLink to="/get_in_touch/ombudsman">
                            <p className="flex items-center gap-2 pt-4 hover:text-green-900 ">
                              <MdSupportAgent size={16} /> Ombudsman
                            </p>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative">
                <button
                  type="button"
                  className="group text-white  inline-flex items-center   focus:outline-none"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(true);
                    setCalculatorCaret(false);
                  }}
                >
                  <div className="nav-link border-r border-white border-opacity-30  px-2 flex items-center">
                    <span
                      className={
                        forumCaret ? " underline underline-offset-4" : ""
                      }
                    >
                      Forum
                    </span>
                    <NavbarCaret state={forumCaret} />
                  </div>
                </button>
                <div
                  className={
                    forumCaret
                      ? "opacity-100 -translate-y-1 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : "hidden opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  }
                  onMouseLeave={() => closeMenu()}
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-green-50 lg:px-6 lg:py-3 p-4">
                      <div
                        className="-m-3 p-3 flex items-start rounded-lg"
                        onClick={() => setForumCaret(false)}
                      >
                        <div className="font-semibold text-green-700 2xl:text-lg">
                          <NavLink to="/forum/rti">
                            <p className="flex gap-3 items-center  hover:text-green-900 ">
                              <BsInfoSquare size={16} /> RTIs
                            </p>
                          </NavLink>
                          <NavLink to="/forum/faq">
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900 ">
                              <BsQuestionOctagon size={16} /> FAQs
                            </p>
                          </NavLink>
                          <NavLink to="/forum/vigilance_cell">
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900">
                              <TbMapSearch size={16} /> Vigilance Cell
                            </p>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative">
                <button
                  type="button"
                  className="group text-white  inline-flex items-center focus:outline-none"
                  onMouseEnter={() => {
                    setAboutUsCaret(false);
                    setProductsCaret(false);
                    setInsuranceCaret(false);
                    setUtilityCaret(false);
                    setImportantLinksCaret(false);
                    setMediaCaret(false);
                    setArchivesCaret(false);
                    setGetInTouchCaret(false);
                    setForumCaret(false);
                    setCalculatorCaret(true);
                  }}
                >
                  <div className="nav-link  px-2 flex items-center">
                    <span
                      className={
                        calculatorCaret ? " underline underline-offset-4" : ""
                      }
                    >
                      Calculator
                    </span>
                    <NavbarCaret state={calculatorCaret} />
                  </div>
                </button>
                <div
                  className={
                    calculatorCaret
                      ? "opacity-100 -translate-y-1 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : "hidden opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  }
                  onMouseLeave={() => closeMenu()}
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="relative grid gap-6 bg-green-50 p-4 lg:px-6 lg:py-3">
                      <div
                        className="-m-3 p-3 flex items-start rounded-lg ]"
                        onClick={() => setCalculatorCaret(false)}
                      >
                        <div className="text-green-700 font-semibold 2xl:text-lg">
                          <NavLink to="/calculator/emi_calculator">
                            <p className="flex gap-3 items-center hover:text-green-900  ">
                              <BsCashCoin size={16} /> EMI Calculator
                            </p>
                          </NavLink>
                          <NavLink to="/calculator/fd_calculator">
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900 ">
                              <GiCash size={16} /> FD Calcultor
                            </p>
                          </NavLink>
                          <NavLink to="/calculator/rd_calculator">
                            <p className="flex gap-2 items-center pt-4 hover:text-green-900">
                              <BsFillCalculatorFill size={16} /> RD Calculator
                            </p>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </>
        {/* DESKTOP NAVBAR END  */}
      </section>
    </div>
  );
}

const NavbarCaret = ({ state }) => {
  return (
    <svg
      className={
        state === true
          ? "transform rotate-180  h-5 w-5 text-green group-hover:text-green-200 transition ease-out duration-200"
          : "transform rotate-0 transition ease-out duration-200  h-5 w-5 text-green group-hover:text-green-200"
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
};
